import { graphql, StaticQuery } from "gatsby";
import React from "react";
import CarouselComponent from "./../common/carousel-component";

function FeaturedJobs() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2.05
    },
    mobile: {
      breakpoint: { max: 767, min: 500 },
      items: 1.3,
    },
    mobile1: {
      breakpoint: { max: 500, min: 0 },
      items: 1
    }
  };

  return (
    <>
      <StaticQuery
        query={graphql`
          query CareersFeaturedJobs {
            SuperOps {
              careers(
                where: { isActive: Yes, isFeatured: Yes }
                orderBy: position_ASC
              ) {
                heroBackgroundColor
                tags {
                  name
                }
                jobTitle
                position
                slug
                location
              }
            }
          }
        `}
        render={data => (
          <CarouselComponent responsive={responsive} swipeable>
            {data.SuperOps.careers.map((c, i) => {
              const { heroBackgroundColor, tags, jobTitle, slug, location } = c
              return (
                <div>
                  <a href={`/careers/${slug}`}>
                    <div
                      className="crd position-relative"
                      style={{ background: heroBackgroundColor }}
                    >
                      <div className="p14 lowercase">
                        <p> {tags[0].name} </p>
                      </div>
                      <h4 className="job-title fw-600 text-deep-purple mb50"> {jobTitle} </h4>
                      <p className="p12 loc-tag m-0 fw-500">location</p>
                      <p className="p14 text-deep-purple fw-500">{location}</p>

                      <div className="a12 position-absolute">
                        <a href={`/careers/${slug}`}>
                          {" "}
                          apply now{" "}
                          <img
                            src="https://media.graphcms.com/2j9zFnn3SWGuSdxuUPVA"
                            alt="arrow"
                            height="10"
                            width="10"
                          />{" "}
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
              )
            })}
          </CarouselComponent>
        )}
      />
    </>
  )
}

export default FeaturedJobs